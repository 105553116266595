<template>
  <el-row class="container">
    <!--头部-->
    <el-col :span="24" class="topbar-wrap">
      <div :class="collapsed?'topbar-logo topbar-btn fold':'topbar-logo topbar-btn'">
        <img src="../assets/images/xshf_logo.png" alt="Logo">
      </div>
      <div :class="collapsed?'topbar-title topbar-btn fold':'topbar-title topbar-btn'" @click.prevent="collapse">
        <!-- <span>
          <i class="el-icon-d-arrow-left" v-show="!collapsed"></i>
          <i class="el-icon-d-arrow-right" v-show="collapsed"></i>
        </span> -->
        <span>{{this.platformMarker}}</span>
      </div>
      <div class="topbar-account topbar-btn">
        <!--欢迎您，您的待办事项共 <router-link to="/system/backlog/list" class="temporary_count">{{backlog_count}}</router-link> 条 ｜-->
        <!--欢迎您，您的待办事项共 <router-link to="/dashboard" class="temporary_count">{{backlog_count}}</router-link> 条 ｜-->
        <el-dropdown trigger="click" class="topbar-dropdown">
          <span class="el-dropdown-link userinfo-inner">欢迎您，<i class="iconfont icon-people"></i> {{sysUserName}} <i class="el-icon-caret-bottom"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item divided @click.native="changePwd">修改密码</el-dropdown-item>
            <el-dropdown-item divided @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </div>
    </el-col>

    <!--中间-->
    <el-col :span="24" class="main">
      <!--左侧导航-->
      <aside :class="collapsed?'fold':''">
        <!--菜单展开时的显示情况-->
        <el-menu default-active="0" @open="handleOpen" @close="handleClose" @select="handleSelect" :collapse="collapsed" unique-opened router>
          <NavMenu :menuDatas="menuDatas" ></NavMenu>
          <div class="support">版权所有：西安点趣</div>
        </el-menu>
      </aside>

      <!--右侧内容区-->
      <section class="content-container">
        <div class="grid-content bg-purple-light">
          <el-col :span="24" class="content-wrapper">
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </el-col>
        </div>
      </section>
    </el-col>

  </el-row>
</template>

<script>
  import { bus } from '../bus.js'
  // import { LeftMenus } from '../mock/system/left_menu'
  import {reqGetLeftMenus,reqUserNameByToken,reqBacklogCount} from '../api/system-api'
  import NavMenu from '../components/menu/NavMenu.vue'

  export default {
    name: 'home',
    components: {
      NavMenu: NavMenu
    },
    created(){
      bus.$on('setUserName', (text) => {
        this.sysUserName = text;
      })
    },
    data () {
      return {
        platformMarker:'',
        sysUserName: '',
        sysUserAvatar: '',
        collapsed: false,
        menuDatas:[],
        backlog_count:0
      }
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },

      handleSelect(index){
        let permissionId=index.substring(index.indexOf("=")+1);
        console.log(permissionId)
        if(permissionId!=870){
            console.log(222233333)
            sessionStorage.setItem('permissionId', permissionId);
        }
      },
      //折叠导航栏
      collapse: function () {
        this.collapsed = !this.collapsed;
      },
      // showMenu(i, status){
      //   this.$refs.menuCollapsed.getElementsByClassName('submenu-hook-' + i)[0].style.display = status ? 'block' : 'none';
      // },
      logout(){
        var _this = this;
          _this.$confirm('确认退出吗?', '提示', {
          type: 'warning'
        }).then(() => {
            _this.$router.push('/login');
            sessionStorage.removeItem('/login');
          sessionStorage.removeItem('Authorization');
        }).catch(() => {

        });
      },
      changePwd(){
          this.$router.push('/system/user/changepwd');
      },

      getUserNameByToken:function (token) {
          this.platformMarker="小程序管理系统";
         reqUserNameByToken({token:token}).then((res)=>{
           if(res.data.code===1){
             this.sysUserName=res.data.data;
           }else {
             this.sysUserName='无法识别身份';
           }
         })
      },

      getBacklogCount(){
          reqBacklogCount().then((res)=>{
            if(res.data.code===1){
              this.backlog_count=res.data.data;
            }else {
              this.backlog_count=0;
            }
          })
      },
    },
    mounted() {
      // this.getBacklogCount();  // 暂时注释掉待办事项数量查询
      //   this.$destroy(false);
      reqGetLeftMenus({}).then((res) => {
        this.menuDatas = res.data.data;
      });
//     假数据的菜单
//      this.menuDatas.unshift(defualtMenu);
//      this.menuDatas = LeftMenus;
//========================================================================
//        代办事项定时查询，暂时先注释掉。
//       this.getBacklogCount();
//       setInterval(this.getBacklogCount,60000);
      var token = sessionStorage.getItem('Authorization');
      if(token){
        this.getUserNameByToken(token);
      }
    }
  }
</script>
<style lang="scss">
  .container {
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;

    .topbar-wrap {
      height: 80px;
      line-height: 80px;
      background: #2f6765;

      .topbar-btn {
        color: #fff;
      }

      .topbar-btn:hover {
        background-color: #2f6765;
      }

      .temporary_count {
        padding: 0 5px;
        background-color: #fff;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 400;
      }
      .topbar-logo {
        float: left;
        width: 200px;
        text-align: center;
        line-height: 80px;
        background-color: #2a5d5b;

        img {
          vertical-align: middle;
        }
      }
      .topbar-logo.topbar-btn:hover {
        background-color: #2f6765;
      }

      .topbar-title {
        float: left;
        padding-left: 10px;
        text-align: center;
        font-size: 22px;
      }
      .topbar-account {
        float: right;
        padding-right: 20px;
        color: #fff;

        .el-dropdown {
          font-weight: 600;
          color: #fff;

          .userinfo-inner{
            cursor: pointer;
            padding-left: 5px;
          }
        }
      }
    }
    .main {
      display: flex;
      position: absolute;
      top: 80px;
      bottom: 0px;
      overflow: hidden;
    }
    aside {
      flex: 0 0 200px;
      width: 200px;
      background-color: #f5f5f5;

      .el-menu {
        height: 100%;
        border-radius: 0px;
        overflow-y: auto;
        background-color: #f5f5f5;

        .iconfont {
          display: inline-block;
          width: 20px;
          vertical-align: baseline;
        }

        .el-submenu {
          min-width: 110px;

          .el-submenu__title {
            .el-menu-item {
              min-width: 110px;
              text-indent: 10px;
            }
          }
        }

        .support {
          position: fixed;
          bottom: 10px;
          left: 40px;
          font-size: 12px;
          color: #BFC6D8;
        }
      }

      .el-menu.collapsed {
        overflow-y: initial;
      }

      .collapsed {
        width: 56px;

        .submenu {
          position: absolute;
          top: 0px;
          left: 56px;
          z-index: 9999;
          height: auto;
          display: none;
        }
      }
    }
    .menu-collapsed {
      flex: 0 0 56px;
      width: 56px;
    }
    .menu-expanded {
      flex: 0 0 200px;
      width: 200px;
    }
    .menu-toggle {
      background: #D1DBE5;
      text-align: center;
      color: #48576A;
      height: 40px;
      line-height: 40px;
    }
    .content-container {
      background: #fff;
      flex: 1;
      overflow-y: auto;
      padding: 10px;

      .content-wrapper {
        background-color: #fff;
        box-sizing: border-box;

        .warp-breadcrum {
          margin-bottom: 10px;
          padding-bottom: 8px;
          border-bottom: 1px solid #DFE6EC;

          .el-breadcrumb {
            line-height: 20px;
          }
        }

        .warp-main {

          .toolbar {
            .el-form-item {
              margin-bottom: 10px;
            }
          }

          .el-form-item {
            margin-bottom: 20px;
            .el-form-item__content {
              .el-rate {
                height: 40px;
                .el-rate__icon {
                  font-size: 24px;
                  line-height: 40px;
                }
                .el-rate__text {
                  font-size: 16px;
                  line-height: 40px;
                }
              }
            }
          }

          .el-loading-mask {
            z-index: 1999;
          }

          .el-form-self {

            .el-form-item__content {
              .el-input-small {
                width: 236px;
                margin-right: -10px;
              }

              .el-date-editor.el-input {
                width: 100%;
              }

              .wangEditor-container {
                border-radius: 4px;
                border: 1px solid #BFC6D9;

                .wangEditor-menu-container {
                  padding-top: 1px;
                  border-bottom: 1px solid #BFC6D9;
                  background: #EEEFF6;
                  .menu-group {
                    border-right: 1px solid #DFE6EC;
                    .menu-item {
                      width: 40px;
                      height: 36px;
                      a {
                        padding: 8px 12px;
                        color: #48506A;
                        line-height: 20px;
                      }

                      a:hover,
                      a.active {
                        background-color: #D1D7E5;
                      }
                      a.selected {
                        color: #1270A7;
                      }
                    }
                  }
                }

                #editor {
                  height: 600px;
                  min-height: 100%;
                }

                .wangEditor-drop-panel {
                  border-top: 2px solid #2f6765;

                  input[type=text]:focus {
                    border-bottom: 2px solid #BFC6D9;
                  }

                  .panel-tab .tab-container a.selected {
                    color: #2f6765;
                    border-bottom: 2px solid #BFC6D9;
                  }

                  textarea {
                    border: 1px solid #BFC6D9;
                  }

                  button {
                    color: #48506A;
                  }

                  button.gray {
                    color: #999;
                  }
                }
              }

              .wangEditor-container * {
                line-height: 1.5;
              }
            }

            .el-input-margin {
              margin-right: 0;
            }

            .el-form-item {

              .el-form-item {
                margin-bottom: 0;
              }

              .el-form-item__content {
                .el-select {
                  display: block;
                }

                .el-checkbox-group {

                  .el-checkbox-button {

                    .el-checkbox-button__inner {
                      margin: 0 5px 5px 0;
                      border: 1px solid #BFC6D9;
                      border-radius: 4px;
                    }

                    :hover .el-checkbox-button__inner {
                      color: #076DA5;
                    }

                    :first-child .el-checkbox-button__inner {
                      border: 1px solid #BFC6D9;
                      border-radius: 4px;
                    }

                    &.is-checked .el-checkbox-button__inner {
                      background-color: #2f6765;
                      border-color: #2f6765;
                      -webkit-box-shadow: none;
                      box-shadow: none;
                    }

                    .is-checked:hover .el-checkbox-button__inner {
                      color: #fff;
                    }

                  }
                }


              }

              .newsImage-uploader,
              .homeBannerImage-uploader {
                line-height: 20px;
                .el-upload {
                  border: 1px solid #d9d9d9;
                  border-radius: 6px;
                  cursor: pointer;
                  position: relative;
                  overflow: hidden;
                }
                .el-upload:hover {
                  border-color: #3E547B;
                }
              }

              .newsImage-uploader-icon,
              .homeBannerImage-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 225px;
                height: 200px;
                line-height: 200px;
                text-align: center;
              }
              .newsImage {
                width: 225px;
                height: 200px;
                display: block;
              }
              .homeBannerImage {
                width: 225px;
                height: 200px;
                display: block;
              }

            }

            .el-form-item.el-input-margin-left {
              margin-left: -12px;
            }

            .el-input-margin-big {
              margin-right: -12px;
            }

            .el-row .el-col .el-select {
              width: 100%;
            }


          }


          .el-form-item-self {
            width: 100%;
            text-align: center;
          }

          .el-form-item-self {
            .el-button {
              width: 150px;
            }
          }

          .el-dialog__wrapper {
            .el-dialog {
              .el-dialog__body {
                .el-form {
                  .el-form-item {
                    .el-form-item__content {
                      .el-radio-group {
                        .el-radio {
                          width: 100px;
                          margin-left: 15px;
                          line-height: 2;
                        }
                      }
                    }
                  }
                }
                .images img {
                  margin: 9px;
                  padding: 1px;
                  vertical-align: top;
                  max-width: 100%;
                }

                .images img:hover {
                  cursor: pointer;
                  background-color: #3E547B;
                }
              }
            }
          }

          .temporary {
            color: #FF4949;
          }

          .form-tag {
            background-color: #E5E9F2;
            font-size: 14px;
            padding-top: 8px;
            padding-left: 10px;
            height: 35px;
            margin-bottom: 20px;
          }

          .form-tag-line {
            padding: 4px 10px;
            font-size: 14px;
            border-bottom: 2px solid #e5e9f2;
            margin-bottom: 20px;
          }

          .form-tag-head {
            padding: 2px 10px;
            font-size: 14px;
            background: #f3f5f7;
            margin-bottom: 20px;
          }

          .form-buttons {
            margin-top: 25px;
          }

          .currentImg {
            visibility: hidden;
            width: 100px;
            height: 50px;
            background-size: cover;
          }

        }

        .zk-table {
          font-size: 14px;
        }

        .table-footer {
          padding: 10px 0;
        }
      }
    }
  }

  .el-dropdown-menu {
    text-align: center;
    .el-dropdown-menu__item {
      font-size: 14px;
      line-height: 32px;
    }
  }

  .el-submenu {

    .el-menu-item {
      min-width: 110px;
    }

  }

  .el-select-dropdown {
    .el-select-dropdown__item.selected,
    .el-select-dropdown__item.selected.hover {
      background-color: #2f6765;
    }
  }

  .viewer-container {
    .viewer-footer ul li {
      list-style: none;
      float: left;
      cursor: pointer;
    }
    .viewer-button {
      background-color:#C53D38;
      background-color:rgba(244,67,54,.8)
    }
  }

</style>
