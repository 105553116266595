<template>
  <div>
    <div v-for="(item,index) in menuDatas" :key="index">
      <el-menu-item v-if="item.children==null||item.children.length===0" :index="item.permissionUrl+'?permissionId='+item.permissionId" class="el-submenu__title">
        <i :class="item.permissionIcon"></i>
        {{item.permissionName}}
      </el-menu-item>
      <el-submenu v-else :index="item.index">
        <template slot="title"><i :class="item.permissionIcon"></i>{{item.permissionName}}</template>
        <NavMenu :menuDatas="item.children"></NavMenu>
      </el-submenu>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NavMenu',
    props: ['menuDatas'],
    data() {
      return {}
    },
    methods: {},
    mounted() {
    }
  }
</script>
